<template>
    <div class="mb-4">
        <CRow class="mb-0">
          <CCol lg="3">
            <CInput  v-model="wo_no" placeholder="WO No."/>
          </CCol>
          <CCol lg="3"  v-if="!is_trucking()">
            <Datepicker v-model="date_needed_from" input-class="form-control" placeholder="Date Needed from" />
          </CCol>
          <CCol lg="3" v-if="!is_trucking()">
            <Datepicker v-model="date_needed_to" input-class="form-control" placeholder="Date Needed  to" />
          </CCol>
          <CCol lg="3" v-if="is_trucking()">
            <Datepicker  v-model="date_needed_from" input-class="form-control" placeholder="Request Date from" />
          </CCol>
          <CCol lg="3" v-if="is_trucking()">
            <Datepicker v-model="date_needed_to" input-class="form-control" placeholder="Request Date to" />
          </CCol>
        
          <CCol lg="1">
          </CCol>
          <CCol lg="2" class="float-right">
            <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select> 
        </CCol>
         
          <CCol lg="3" >
            <v-select 
            v-if="!is_trucking()"
              label="setting_name" 
              :options="[
                  {
                      setting_name: 'Vehicle',
                      id: 'vehicle'
                  },
                  {
                      setting_name: 'Equipment',
                      id: 'equipment'
                  },
                  {
                      setting_name: 'Furnishing',
                      id: 'furnishing'
                  }
              ]"
              :reduce="item => item.id"
              v-model="asset_type"
              placeholder="Asset Type"
            > 
            </v-select>  
          </CCol>
          <CCol lg="3">
            <v-select 
              label="asset_no" 
              :options="equipmentList.data"
              :reduce="item => item.id"
              v-model="asset_no"
              placeholder="Asset No."
              v-if="asset_type == 'equipment'"
            > 
            </v-select>  
            <v-select 
              label="asset_no" 
              :options="furnishingList.data"
              :reduce="item => item.id"
              v-model="asset_no"
              placeholder="Asset No."
              v-if="asset_type == 'furnishing'"
            > 
            </v-select>  
            <v-select 
              label="plate_no" 
              :options="vehicleList.data"
              :reduce="item => item.id"
              v-model="asset_no"
              placeholder="Plate No."
              v-if="asset_type == 'vehicle'"
            > 
            </v-select>  
          </CCol>
         
         
          <CCol lg="1">
          </CCol>
          <CCol lg="2" class="float-right">
            <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
          </CCol>
        </CRow>
        <CRow class="mt-3">
        
          <CCol lg="3" v-if="!is_trucking()"> 
            <v-select 
              label="customer_name" 
              :options="customerList.data"
              :reduce="item => item.id"
              v-model="dept_id"
              placeholder="Select Dept / Proj" 
             
            > 
            </v-select>  
          </CCol>
          <CCol lg="3"  v-if="!is_trucking()">
            <v-select 
              label="setting_name" 
              :options="[
                  {
                      setting_name: 'Preventive Maintenance',
                      id: 'preventive maintenance'
                  },
                  {
                      setting_name: 'Repair',
                      id: 'repair'
                  },
                  {
                      setting_name: 'Change',
                      id: 'change'
                  },
                {
                  setting_name: 'Dismantling',
                  id: 'dismantling'
                }, 
                {
                  setting_name: 'Installation',
                  id: 'installation'
                },
                {
                  setting_name: 'Checking & Diagnose',
                  id: 'checking & diagnose'
                },
                {
                  setting_name: 'Rehabilitation',
                  id: 'rehabilitation'
                }, 
                {
                  setting_name: 'Recondition',
                  id: 'recondition'
                }, 
                  {
                      setting_name: 'Others',
                      id: 'others'
                  },
                  
              ]"
              :reduce="item => item.id"
              v-model="wo_type"
              placeholder="WO Type"
             
            > 
            </v-select>  
          </CCol>
         
          
          <CCol lg="1">
          </CCol>
          <CCol lg="2" class="float-right"> 
          </CCol>
        </CRow>
    </div>
  </template>
  
  <script>
    import config from '../config.js';
    import axios from '../axios';
    import vSelect from 'vue-select'
    import Datepicker from 'vuejs-datepicker';
    import 'vue-select/dist/vue-select.css';
      export default {
          name: "BookingTypeSearch",
          props: {
            status: {
              default : 'all'
            }
          },
          data() {
                return {
                  wo_no: "",
                  depot_id: "",
                  date_needed_from: "",
                  date_needed_to: "",
                  wo_type: "",
                  asset_type: "",
                  asset_no: "",
                  srf_no: "", 
                dept_id: "",
                  vehicleList: {
                      data: []
                  },
                  depotList : {
                    data: []
                  },
                  equipmentList: {
                      data: []
                  },
                  furnishingList: {
                      data: []
                  },
          customerList: {
          data: []
        },
                }
          },
        mounted() {
          this.getEquipment();
          this.getFurnishing();
          this.getDepot();
          this.getVehicle();
         this.getCustomer();
        },
        components: {vSelect, Datepicker},
        methods: {
          search() {
            const data = {
              wo_no: this.wo_no,
              depot_id: this.depot_id,
              date_needed_from: this.date_needed_from,
              date_needed_to: this.date_needed_to,
              wo_type: this.wo_type,
              asset_type: this.asset_type, 
              asset_no: this.asset_no,
              srf_no: this.srf_no, 
              dept_id: this.dept_id,
              status: this.status
            }
            this.$emit("depot-search-query", data)
          },
          clearSearch() {
            this.wo_no = "";
            this.depot_id = "";
            this.date_needed_from = "";
            this.date_needed_to = "";
            this.wo_type = "";
            this.asset_type = ""; 
            this.asset_no = "";
            this.srf_no = ""; 
            this.dept_id = "";
            const data = {
              wo_no: this.wo_no,
              date_needed_from: this.date_needed_from,
              depot_id: this.depot_id,
              date_needed_to: this.date_needed_to,
              wo_type: this.wo_type,
              asset_type: this.asset_type, 
              asset_no: this.asset_no,
              srf_no: this.srf_no, 
              dept_id: this.dept_id
            }
            this.$emit("depot-search-query", data)
          },
          getDepot(){

          axios.get(config.api_path+'/reference/settings-list',{
              params:{
                setting_type:'depot'
              }
                })
          .then(response => {
            this.depotList = response.data; 
          })

          },
            
          getEquipment(){
  
              axios.get(config.api_path+'/reference/equipment-list')
              .then(response => {
                  this.equipmentList = response.data;
                  this.equipmentList.data = this.equipmentList.data.map((value, index)=>{
                      value.value = value.id;
                      value.label = value.asset_no;
                      return value;
                  })
              })
  
          },
           getFurnishing(){
  
              axios.get(config.api_path+'/reference/furnishing-list')
              .then(response => {
                  this.furnishingList = response.data;
                  this.furnishingList.data = this.furnishingList.data.map((value, index)=>{
                      value.value = value.id;
                      value.label = value.asset_no;
                      return value;
                  })
              })
  
          },
           getCustomer(){
              axios.get(config.api_path+'/reference/customer-list', {
                  params: {
                      show_all : true
                  }
              })
              .then(response => {
                  this.customerList = response.data;    		
              })
          }, 
  
          getVehicle(){
  
              axios.get(config.api_path+'/reference/vehicle-list')
              .then(response => {
                  let data = response.data.data;
                  let holder = [];
                  data.forEach(element => {
                      if(element.plate_no && element.plate_no !== 'null') holder.push(element)
                  });
                  this.vehicleList.data = holder;
                  this.vehicleList.data = this.vehicleList.data.map((value, index)=>{
                      value.value = value.id;
                      value.label = value.plate_no;
                      return value;
                  })
              })
          },
        }
      }
  </script>
  
  <style scoped>
  
  </style>
  